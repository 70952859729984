
<script>
import { defineComponent, computed, nextTick, ref, onMounted, onBeforeUnmount, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { dispatchEvent } from '@kit/utils/EventBus'
import { animate, actionSetOA2T, actionOA2T } from '@kit/utils/Animate'
import { searchWidgetMain } from '@project/themes'
import { sleep } from '@kit/utils/Sleep'
import { mergeClassesTheme } from '@kit/utils/Formats'
import { useRoute } from 'vue-router'
import Config from '@kit/utils/Config'

/*  

{
  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  //the logo and aria text
  logoSrc:pathname("@images/@images/7Tribes_Logo_Words.svg"),
  logoAriaAlt:"7-tribes navigation logo",

  //the duration of the animation to reveal the subitems.
  subitemsRevealDuration: 200,

  //the type of subitem element. Default is div
  //NOTE this is not reskinnable
  subitemType: "div",

  //logo wrapper classes
  logoWrapperClasses:"some class",
  logoClasses:"some class",
  
  //mergeable classes for the hamburger button
  hamburgerButtonClasses: "some class",


  //the mergeable classes for the outer wrappers for mobile
  outerContainerClassesDesktopScrolled: "some class",
  outerContainerClassesMobileScrolled: "some class",

  //the background color of the nav bar. Optional. Default is white
  backgroundColor: "#F2F2F2",

  //optional. defaults to true. do you want the background color to animate
  //during the switch to mobile
  animateBackgroundChange: true

  //The background color to fade to when the user has scrolled down and 
  //the nav-bar is in the shrink state. Optional. Default is white
  shrinkColor: "#E90055",

  //The animation time for the shrink. default is 400ms.
  shrinkDuration: 400

  //required
  height: "90px",
  shrinkHeight: "50px",

  //optional. The top gap
  topGap: "30px"



}

Emits:

TopOn - when the user has scrolled right to the top
TopOff - when the scrolled away from the top

*/

const defaultConfig = new Config({
  scrollShrink: true, 
  topGap: "0px",
  subitemsRevealDuration: 200,
  backgroundColor: '#FFFFFF',
  shrinkColor:'#FFFFFF',
  shrinkDownDuration:400,
  shrinkUpDuration:100,
  backgroundProperty:'background',
  subitemType: 'div',
  animateBackgroundChange: true,

  height: "90px",
  shrinkHeight: "50px",
  mobileHeight: "50px",

  logoAriaAlt: "navigation logo",
  logoWrapperClasses: '',
  logoClasses: '',

  //ALEX new
  navItemClasses: '',
  dropDownNavItemClasses: '',
  navSubitemsClasses: '',

  mobileMiddleAreaClasses: '',

  //Which layout do you want?
  desktopLayout: 'simple' ,

  //simple: 
  //
  //  ---------------------------------------------------------------------------
  //  |           |                                                             |
  //  |           |                                                             |
  //  |    Logo   |                        Nav Items                            |
  //  |           |                                                             |
  //  |           |                                                             |
  //  ---------------------------------------------------------------------------

  //
  //banner:
  //
  //  ---------------------------------------------------------------------------
  //  |           |                                          |                  |
  //  |           |        Top Area For Banner               |   Optional right |
  //  |    Logo   |           (top-area)                     |   area           |
  //  |           |------------------------------------------|   (right-area)   |
  //  |           |         Nav Items (nav-area)             |                  |
  //  ---------------------------------------------------------------------------

  banner: {
    topAreaClasses:"",
    navAreaClasses:"",
    rightAreaClasses:"",
    hasRightArea: true
  }

})

export default defineComponent({
  name: "NavBarBase",
  props:["theme"],
  emits: ['TopOn', 'TopOff'],
  setup(props, context) {
    const store = useStore()
    const hamburgerButton = ref(null)
    const goSmallTrigger = ref(null)
    const desktopNav = ref(null)
    const desktopLogo = ref(null)
    const mobileNav = ref(null)
    const mobileLogo = ref(null)
    const atTop = ref(true)

    //The current mouse clientX/Y.
    let currentMouseX = 0
    let currentMouseY = 0
    let scrolledToTop = true
    let numHoverItems = 0
    let firstHoverItem = null
    let lastHoverItem = null
    let runHoverItemInterval = null
    let navOptions = null

    //Some properties for the dom-nodes
    const rollToggle = "__f8idef"
    const addOnlyOnce = "__0rpdu3"
    const hoverProp = "__mvmd"
    const setInitial = "__mfi8w"

    const addOrRefreshEventListeners = () => {

      const subitemType = getProp('subitemType')
      navOptions = document.querySelectorAll(".navbar-dropdown-item")

      for(let i=0; i<navOptions.length; i++) {
        const navOption = navOptions[i]
        const dropDown = navOption.querySelector(subitemType)
        const mouseOverFunc = navOption[navOptionMouseOverHandler]
        const mouseOutFunc = navOption[navOptionMouseOutHandler]

        if(mouseOverFunc) {
          navOption.removeEventListener("mouseover", mouseOverFunc)
        }
        if(mouseOutFunc) {
          navOption.removeEventListener("mouseout", mouseOutFunc)
        }

        const newMouseOver = (_e) => { 
          navOptionMouseOver(navOption, dropDown)
        }
        const newMouseOut = (_e) => { 
          navOptionMouseOut(navOption, dropDown)
        }

        navOption.addEventListener("mouseover", newMouseOver)
        navOption.addEventListener("mouseout", newMouseOut)

        navOption[navOptionMouseOverHandler] = newMouseOver 
        navOption[navOptionMouseOutHandler] = newMouseOut
      }

    }

   
    const themer = inject("themer")
    const { getProp, reskin } = themer({props, defaultConfig, postSkin:addOrRefreshEventListeners, context })


    const navOptionMouseOverHandler = "_84u43rf3"
    const navOptionMouseOutHandler = "_0gori38"

    const route = useRoute()

    let intObserver = null

    const inMobile = computed(() => {
      return store.state.inMobile
    })

    const userAccount = computed(() => {
      return store.state.account
    })
    const drawerOut = computed(() => {
      return store.state.drawerOut
    })


    //animations for the drop-down menus for the top-nav
    const navDropDownAnimate = actionSetOA2T([
      {
        name:"userRolledOn",
        async action(optionSet, optionElement) {

          optionSet.style.display = "flex"
          optionSet.style.zIndex = 1001
          //stupid thing needs an explicit transform set this way
          //god ui programming is tedious.
          if(!optionSet[setInitial]) {
            optionSet[setInitial] = true
            optionSet.style.transform = "translateY(20px)"
          }

          await animate({
            targets:optionSet,
            translateY:"0px",
            opacity:"1",
            duration:getProp('subitemsRevealDuration'),
            easing:"easeOutQuad"
          })  
          
        }
      },
      {
        name:"userRolledOff",
        async action(optionSet, optionElement) {
          optionSet.style.zIndex = 1000
          await animate({
            targets:optionSet,
            translateY:"20px",
            opacity:"0",
            duration:getProp('subitemsRevealDuration'),
            easing:"easeOutQuad"
          })  
          optionSet.style.display = "none"  
        }
      }
    ], {
      element(_optionSet, optionElement) {
        return optionElement
      },
    })

    //Animation for the nav-bar to go to scrolling mode.
    const navTransitionActions = actionSetOA2T([
      {
        name:"userScrolledDown",
        async action(mobile, snap) {
          context.emit("TopOff")
          atTop.value = false
          const visibleNav = mobile ? mobileNav.value : desktopNav.value
          const invisibleNav = !mobile ? mobileNav.value : desktopNav.value
          const logo = mobile ? mobileLogo.value.$el : desktopLogo.value.$el
          
          invisibleNav.style.top = "0px"
          const duration = snap ? 0 : getProp('shrinkDownDuration')
          const shrinkColor = getProp('shrinkColor')
          const backgroundColor = getProp('backgroundColor')  
          const shrinkHeight = getProp('shrinkHeight')
          const backgroundColorChanged = (shrinkColor != backgroundColor && shrinkColor && backgroundColor)

          const animationObj = !mobile ? {
            targets:visibleNav,
            top:"0px",
            height:shrinkHeight,
            duration,
            easing:"easeOutQuad"
          } : {
            targets:visibleNav,
            top:"0px",
            duration,
            easing:"easeOutQuad"            
          }

          if(backgroundColorChanged) {
            const animate = getProp('animateBackgroundChange')
            const bkProp = getProp('backgroundProperty')

            if(animate) {
              animationObj[bkProp] = shrinkColor
            } else {
              visibleNav.style[bkProp] = shrinkColor
            }
          }

          animate(animationObj)  
         
        }
      },
      {
        name:"userScrolledToTop",
        async action(mobile, snap, sideDrawerCase) {
          context.emit("TopOn")
          atTop.value = true
          const topGap = getProp('topGap')
          const shrinkColor = getProp('shrinkColor')
          const backgroundColor = getProp('backgroundColor')  
          const height = getProp('height')
          const hasTopGap = !!topGap
          const visibleTop = mobile ? "0px" : topGap
          const visibleNav = mobile ? mobileNav.value : desktopNav.value
          const invisibleNav = !mobile ? mobileNav.value : desktopNav.value
          //const logo = mobile ? mobileLogo.value.$el : desktopLogo.value.$el
          
          invisibleNav.style.top = !mobile ? "0px" : topGap
          const duration = snap ? 0 : getProp('shrinkUpDuration')
          const backgroundColorChanged = (shrinkColor != backgroundColor && shrinkColor && backgroundColor )


          const animationObj = ! mobile ? {
            targets:visibleNav,
            height: height,
            duration,
            easing:"easeOutQuad"
          } : {
            targets:visibleNav,
            duration,
            easing:"easeOutQuad"     
          }

          if(hasTopGap) {
            animationObj.top = visibleTop
          }
          if(backgroundColorChanged) {

            //What's the deal with this sideDrawerCase changing the background-color to the shrink-color?
            //See the deal is that the side-drawer was activated, and we need the background of the navbar 
            //to switch to an "ordinary" color, which probably is the shrink-color. If we're doing changes in 
            //the background is means that there's probably something funky and artistic going on at the top of the 
            //page. That's cool but when we call the side-drawer, we want things to look a little more ordinary.
            //Why not just have a separate skin specifically for the nav-bar during mobile nav-bar situations?
            //because reskinning is not easy. TODO, Alex, 2024. It's easier now. Reevaluate this.
            // if(sideDrawerCase) {
            //   animationObj.background = props.theme.shrinkColor
            // } else {
            //   animationObj.background = props.theme.backgroundColor
            // }
            const targetColor = sideDrawerCase ? shrinkColor : backgroundColor
            const bkProp = getProp('backgroundProperty')

            if(animate) {
              animationObj[bkProp] = targetColor
            } else {
              visibleNav.style[bkProp] = targetColor
            }
        
          } 

          animate(animationObj)

        }
      }
    ])



    //https://stackoverflow.com/questions/923299/how-can-i-detect-when-the-mouse-leaves-the-window
    //document.mouseout is triggered when:
    //You move the cursor onto the title bar.

    //Make a new item for this hover linked-list
    const newHoverItem = (navOption, dropDown) => {
      return { prev:null, next:null, navOption, dropDown }
    }

    //run the a single hover-item
    const runHoverItem = ({ navOption, dropDown}, mouseleave ) => {

      //if mouseleave, then that means that the user just rolled off the window, so everything is going 
      //to perform the rolled-off transition
      if(mouseleave) {
        const item = navOption[hoverProp]
        removeHoverItemFromChain(item)
        navOption[rollToggle] = false
        actionOA2T(navDropDownAnimate, "userRolledOff", [dropDown, navOption])
      } 
      
      //else, we're going to check for the boundaries of the mouse within the actual ui elements.
      //We can get away with this because the top nav is a fixed element. getBoundingClientRect 
      //causes a reflow, but that's tightly bounded because we're right in a fixed element so it 
      //won't hit the CPU too hard.
      //https://medium.com/geekculture/the-browser-reflow-whereabouts-c3d963eabe4a
      else {

      const x = currentMouseX
      const y = currentMouseY

      const { top:t1, bottom:b1, right:r1, left:l1 } = navOption.getBoundingClientRect()
      const { top:t2, bottom:b2, right:r2, left:l2 } = dropDown.getBoundingClientRect()

      const isOutsideOfNavOption = y < t1 || y > b1 || x < l1 || x > r1 
      const isOutsideOfDropDown = y < t2 || y > b2 || x < l2 || x > r2
      const isOutsideOfRegion = isOutsideOfDropDown && isOutsideOfNavOption 

        if(isOutsideOfRegion) {
          const item = navOption[hoverProp]
          removeHoverItemFromChain(item)
          navOption[rollToggle] = false
          actionOA2T(navDropDownAnimate, "userRolledOff", [dropDown, navOption])
        }
      }
    }

    //run the whole hover-item chain
    const runHoverItemChain = (mouseleave) => {
      let fxInfo = firstHoverItem
      while(fxInfo) {
        const next = fxInfo.next
        runHoverItem(fxInfo, mouseleave)
        fxInfo = next
      }
    }

    //start the poll
    const startHoverItemInterval = () => {
      runHoverItemInterval = setInterval(() => {
        runHoverItemChain(false)
      },500)
    }

    //end the poll
    const endHoverItemInterval = () => {
      clearInterval(runHoverItemInterval)
      runHoverItemInterval = null
    }

    //Add the hover-item to the chain
    const addHoverItemToChain = (fxInfo) => {

      if(fxInfo.navOption[hoverProp]) {
      return
      }

      fxInfo.navOption[hoverProp] = fxInfo

      if(numHoverItems == 0) {
        firstHoverItem = fxInfo
        lastHoverItem = fxInfo
        startHoverItemInterval()
      } else {

        if(lastHoverItem) {
          lastHoverItem.next = fxInfo
        }
        fxInfo.prev = lastHoverItem
        lastHoverItem = fxInfo

      }
      numHoverItems++
    }

    //remove the hover-item from the chain
    const removeHoverItemFromChain = (fxInfo) => {

      if(!fxInfo.navOption[hoverProp]) {
      return
      }

      fxInfo.navOption[hoverProp] = null

      if(fxInfo.prev) {
        fxInfo.prev.next = fxInfo.next
      } else {
        firstHoverItem = fxInfo.next
      }
      if(fxInfo.next) {
        fxInfo.next.prev = fxInfo.prev
      } else {
        lastHoverItem = fxInfo.prev
      }
      numHoverItems--

      if(!firstHoverItem && !lastHoverItem) {
        endHoverItemInterval()
      }
    }


    /**
     * So here's the deal. The mouse coordinates in the event aren't quite right.
     * We need to wait for a fraction of a second to get where the mouse coordinates
     * ACTUALLY are.
     * 
     * When we have those, then we're going to compare them with the client-rect of 
     * the navOption and see if we have a hit. Have to do this because we don't want
     * mouse-overs on the dropdown menu triggering mouseover
     * 
     * Note that the mouse coords are clientX/Y, which is in the same coordinate system
     * as the getBoundingClientRect
     * 
     */
    const navOptionMouseOver = async(navOption, dropDown) => {
      await sleep(50)
      const x = currentMouseX
      const y = currentMouseY
      const { top, bottom, right, left } = navOption.getBoundingClientRect()
      const isOutside = y < top || y > bottom || x < left || x > right
      if(!isOutside && !navOption[rollToggle]) {
        addHoverItemToChain(newHoverItem(navOption, dropDown))
        navOption[rollToggle] = true
        actionOA2T(navDropDownAnimate, "userRolledOn", [dropDown, navOption])
      }
    }


    /**
     * Similar mechanics to the function above.
     * 
     * So we got a mouse-out event, eh? But see, every single tiny little damn 
     * thing triggers a mouseout event. So is this for real? So what we do 
     * is was check the bounds of both the nav option as well as the drop-down
     * selections to see if the user ACTUALLY rolled out.
     * 
     * 
     */
    const navOptionMouseOut = async(navOption, dropDown) => {

      if(!dropDown) {
        const subitemType = getProp('subitemType')
        throw new Error(`Error: The dropdown does not exists. Could be a problem with the structure your nav. I'm expecting the subitems to be contained in an element of type: '${subitemType}'`)
      }

      await sleep(50)
      const x = currentMouseX
      const y = currentMouseY

      const { top:t1, bottom:b1, right:r1, left:l1 } = navOption.getBoundingClientRect()
      const { top:t2, bottom:b2, right:r2, left:l2 } = dropDown.getBoundingClientRect()

      const isOutsideOfNavOption = y < t1 || y > b1 || x < l1 || x > r1 
      const isOutsideOfDropDown = y < t2 || y > b2 || x < l2 || x > r2
      const isOutsideOfRegion = isOutsideOfDropDown && isOutsideOfNavOption 

      if(isOutsideOfRegion && navOption[rollToggle]) {

        const item = navOption[hoverProp]
        removeHoverItemFromChain(item)
        navOption[rollToggle] = false
        actionOA2T(navDropDownAnimate, "userRolledOff", [dropDown, navOption])
      }
    }

    onMounted(() => {

      //Get the nav-options
      navOptions = document.querySelectorAll(".navbar-dropdown-item")

      //Do this so event listeners only get added once.
      if(!window[addOnlyOnce]) {
        window[addOnlyOnce] = true

        //we attach a listener so that we get continual reports of the mouse position
        //we can't just read the mouse coords off of the event- we tend to get out-of-date
        //information that way.        
        window.addEventListener('mousemove', (e) => {
          currentMouseX = e.clientX;
          currentMouseY = e.clientY;
        })

        document.addEventListener('mouseleave', (e) => {
          runHoverItemChain(true)
        })

        addOrRefreshEventListeners()
    
      }


      //Listen for the intersection so that we can shift the nav around to make 
      //it look slick when we're scrolling.
      intObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            scrolledToTop = true
            actionOA2T(navTransitionActions, "userScrolledToTop", [inMobile.value, false, false])
          } else {
            scrolledToTop = false
            actionOA2T(navTransitionActions, "userScrolledDown", [inMobile.value, false])
          }
        })
      },
      {
        rootMargin: "0px",
        root: null,
        threshold: 0.1
      })

      intObserver.observe(goSmallTrigger.value)
    })

    onBeforeUnmount(() => {
      intObserver.unobserve(goSmallTrigger.value)
      intObserver = null
    })

    watch(inMobile, (newVal, _oldVal) => {

      if(!scrolledToTop) {
        actionOA2T(navTransitionActions, "userScrolledDown", [newVal, true])
      } else {
        actionOA2T(navTransitionActions, "userScrolledToTop", [newVal, true, false])
      }  
    })

    watch(route, (_newVal,_oldVal) => {
      if(!scrolledToTop) {
        context.emit("TopOff")
      } else {
        context.emit("TopOn")
      }
    })

    watch(drawerOut, (newVal, oldVal) => {
      //if the drawer is moved back out, either by the user click the hamburger button
      //or by the user making the browser-window wider, then we're going to see 
      //if the page is scrolled to the top and if not, then we're going to apply the 
      //action to move the top-nav to it's minimal configuration.
      if(!newVal && oldVal) {

        if(!scrolledToTop) {
          actionOA2T(navTransitionActions, "userScrolledDown", [true])
        } else {
          //true: yes we're in mobile 
          //true: yes perform the animation in snap mode 
          //false, no we're not in the sidedrawer case anymore.
          actionOA2T(navTransitionActions, "userScrolledToTop", [true, true, false])
        }
      }
    })

    const sideDrawerOut = () => {
      if(store.state.drawerOut) {
        dispatchEvent("drawerMove", false)
        //the userScrolledDown action is handled by the watcher on the drawer-out,
        //because in addition to the hamburger button, we also have the possibility 
        //that the user can get rid of the drawer by making the browser window bigger.
        hamburgerButton.value.setAttribute("aria-expanded", false)
      } else {

        //trigger the top styles
        actionOA2T(navTransitionActions, "userScrolledToTop", [true, true, true])

        dispatchEvent("drawerMove", true)
        hamburgerButton.value.setAttribute("aria-expanded", true)
      }
    }

    //The initial style for the container.
    const uiContainerInitialStyleDesktop = computed(() => {
      return `height:${getProp('height')}; top:${getProp('topGap')}; background:${getProp('backgroundColor')}`
    })

    const uiContainerInitialStyleMobile = computed(() => {
      return `height:${getProp('mobileHeight')}; background:${getProp('backgroundColor')}`
    })


    const navItemClasses = computed(() => {
        return (atTop.value ? `navbar-item navbar-item--attop ` : 'navbar-item ') + mergeClassesTheme('sb sb-h sb-align-cv nav-top-option', getProp('navItemClasses'))
    })

    const dropDownNavItemClasses = computed(() => {
      return (atTop.value ? `navbar-dropdown-item nav-dropdown-item--attop ` : 'navbar-dropdown-item ' ) + mergeClassesTheme('sb sb-h sb-align-cv nav-top-option', getProp('dropDownNavItemClasses'))
    })

    const navSubItemClasses = computed(() => {
      return (atTop.value ? `navbar-subitems navbar-subitems--attop ` : 'sb sb-v navbar-subitems ') + mergeClassesTheme('sb sb-v', getProp('navSubitemsClasses'))
    })

    /////////////
    //  A P I  //
    /////////////

    return { 
      reskin,
      atTop,
      mergeClassesTheme,
      uiContainerInitialStyleDesktop,
      uiContainerInitialStyleMobile,
      getProp,
      sideDrawerOut,
      hamburgerButton, 
      inMobile, 
      drawerOut,
      goSmallTrigger, 
      desktopNav, 
      desktopLogo, 
      mobileNav, 
      mobileLogo,
      userAccount,
      searchWidgetMain,
      navItemClasses,
      dropDownNavItemClasses,
      navSubItemClasses

    }
  }
});
</script>


<style>

.navbar-dropdown > .navbar-subitems {
  opacity: 0;
  transform: translateY(20px); 
  box-shadow: 0px 10px 10px rgba(0,0,0, 0.5);
}

.navbar-fixed-ui-container {
  position:fixed; 
  color:#555555; 
  z-index:100; 
  background:white; 
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.5);
}

.navbar-subitems {
  display:none;
  position:absolute !important; 
  left:0px; 
  top:100%;  
  background:#FFFFFF;
}

.navbar-logo {
  max-height: 100%;
  width: 100%;
  height: auto;
  object-fit:contain;
  object-position:50% 50%;
}

.hamburger-button {
  margin-top:10px; 
  margin-bottom:10px;
  margin-right:20px; 
  border-top:4px solid #555555; 
  border-bottom:4px solid #555555; 
  width: 50px;
  height:30px;
  cursor:pointer;
}
.hamburger-button::after {
  content:"";
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  height:4px;
  left:0;
  right:0;
  background:#555555;
}


@media (min-width : 960px) {

  .visible-gtm {
    display: flex;
  }
  .invisible-gtm {
    display: none !important;
  }

}

@media (max-width : 960px) {

  .visible-gtm {
    display: none !important;
  }
  .invisible-gtm {
    display: flex;
  }

}

.navbar-outer-container > .sb.sb-explicit {
  width:100% !important;
}

@media (min-width: 1600px) {
  .navbar-outer-container > .sb.sb-explicit {
    width:1200px;
  }
}

</style>

<style scoped> 
  a:visited {
    text-decoration: none;
    font-weight: normal;
  }
</style>

<template>

  <nav 
    ref="mobileNav"
    :aria-hidden="!inMobile" :tab-index="inMobile ? 0 : -1" 
    :style="uiContainerInitialStyleMobile"
    :class="mergeClassesTheme('navbar-fixed-ui-container sb sb-h sb-greedy-1 top-nav invisible-gtm', getProp('outerContainerClassesMobile'))">
    
    <router-link ref="mobileLogo" to="/" :class="'navbar-logo-wrapper ' + (atTop ? 'navbar-logo-wrapper--attop ' : '') + mergeClassesTheme('sb sb-explicit', getProp('logoWrapperClasses'))">
      <img :class="'navbar-logo ' + getProp('logoClasses')" :src="getProp('logoSrc')" :alt="getProp('logoAriaAlt')">
    </router-link>

    <div :class="'navbar-mobile-middle-area '+mergeClassesTheme('sb sb-h sb-greedy', getProp('mobileMiddleAreaClasses'))">
      <slot name="nav-mobile-middle-area"></slot>
    </div>
    
    <div class="sb sb-h sb-align-cv">
      <div 
        @click="sideDrawerOut" 
        ref="hamburgerButton"
        aria-role="button"
        aria-controls="SideNavDrawer_sideDrawer" 
        aria-expanded="false"
        aria-label="Button for responsive side-sliding navigation drawer"
        :class="mergeClassesTheme('sb sb-h sb-explicit hamburger-button', getProp('hamburgerButtonClasses'))">
      </div>
    </div>
  </nav>


  <nav
    v-if="getProp('desktopLayout') == 'simple'"
    ref="desktopNav"
    :aria-hidden="inMobile" :tab-index="inMobile ? -1 : 0"
    :style="uiContainerInitialStyleDesktop"
    :class="(atTop ? 'navbar-outer-container navbar-outer-container--attop ' : 'navbar-outer-container ') + mergeClassesTheme('navbar-fixed-ui-container sb sb-h sb-align-ch top-nav visible-gtm', getProp('outerContainerClassesDesktop'))">
    <div :class="mergeClassesTheme('sb sb-h sb-explicit sb-greedy-1', getProp('innerContainerClassesDesktop'))">
      <router-link ref="desktopLogo" to="/" :class="'navbar-logo-wrapper ' + (atTop ? 'navbar-logo-wrapper--attop ' : '') + mergeClassesTheme('sb sb-text', getProp('logoWrapperClasses'))">
        <img :class="mergeClassesTheme('navbar-logo', getProp('logoClasses'))" :src="getProp('logoSrc')" :alt="getProp('logoAriaAlt')">
      </router-link>
      <div class="sb sb-h sb-greedy nav-spacer"></div>
      <slot 
        :navItemClasses="navItemClasses" 
        :dropDownNavItemClasses="dropDownNavItemClasses"
        :navSubItemClasses="navSubItemClasses"
      name="nav-items"></slot>
    </div>
  </nav>

  <nav
    v-else-if="getProp('desktopLayout') == 'banner'"
    ref="desktopNav"
    :aria-hidden="inMobile" :tab-index="inMobile ? -1 : 0"
    :style="uiContainerInitialStyleDesktop"
    :class="(atTop ? 'navbar-outer-container navbar-outer-container--attop ' : 'navbar-outer-container ') + mergeClassesTheme('navbar-fixed-ui-container sb sb-h sb-align-ch top-nav visible-gtm', getProp('outerContainerClassesDesktop'))">
    <div :class="(atTop ? 'navbar-inner-container navbar-inner-container--attop ' : 'navbar-inner-container ') + mergeClassesTheme('sb sb-h sb-explicit sb-greedy-1', getProp('innerContainerClassesDesktop'))">
      
      <slot name="nav-inner-container"></slot>

      <router-link ref="desktopLogo" to="/" :class="(atTop ? 'navbar-logo-wrapper navbar-logo-wrapper--attop ' : 'navbar-logo-wrapper ') + mergeClassesTheme('sb sb-explicit', getProp('logoWrapperClasses'))">
        
        <img :class="(atTop? 'navbar-logo navbar-logo--attop ' : 'navbar-logo ') +  getProp('logoClasses')" :src="getProp('logoSrc')" :alt="getProp('logoAriaAlt')">
      
      </router-link>

      <div class="sb sb-v sb-greedy sb-greedy-1">
        <div :class="(atTop ? 'navbar-banner-top-area navbar-banner-top-area--attop ' : 'navbar-banner-top-area ') + mergeClassesTheme(`sb sb-h sb-greedy`, getProp('banner.topAreaClasses'))">
          <slot name="top-area"></slot>
        </div>
        <div :class="(atTop ? 'navbar-banner-nav-area navbar-banner-nav-area--attop ' : 'navbar-banner-nav-area ') + mergeClassesTheme(`sb sb-h`, getProp('banner.navAreaClasses'))">

          <slot :navItemClasses="navItemClasses" 
          :dropDownNavItemClasses="dropDownNavItemClasses"
          :navSubItemClasses="navSubItemClasses"
          name="nav-area"></slot>
        </div>
      </div>

      <div v-if="getProp('banner.hasRightArea')" :class="(atTop ? 'navbar-banner-right-area navbar-banner-right-area--attop ' : 'navbar-banner-right-area ') + mergeClassesTheme(`sb sb-v sb-explicit`, getProp('banner.rightAreaClasses'))">
        <slot name="right-area"></slot>
      </div>

    </div>


  </nav>


  <div ref="goSmallTrigger" id="top-of-page" class="sb sb-abox" style="width:100%; height:50px;"></div>



</template>